
import Vue from 'vue'
import Component from 'vue-class-component'
import Moderation from '@/apis/Moderation'
import Partners from '@/apis/Partners'
import { Watch } from 'vue-property-decorator'
@Component({
  name: 'AddUser'
})

export default class AddUser extends Vue {
  statuses = []
  brands = []
  networks = []
  cities = []
  regions = []
  managers = []
  dealershipDetails = {}
  initiatorUser = {}
  moderationId = null

  searchCityInput = ''
  citiesMatches = this.cities

  @Watch('searchCityInput')
  onSearchCityInputChange(v: string) {
    this.queryCitySelections(v)
  }

  queryCitySelections(v: string) {
    if (v !== null) {
      // @ts-ignore
      this.citiesMatches = this.cities.filter((item) => item.name.toLowerCase().startsWith(v.toLowerCase()))
    } else {
      this.citiesMatches = this.cities
    }
  }

  created() {
    // @ts-ignore
    this.moderationId = this.$router.currentRoute.params.id
    this.fetchDetailsForUpdateModeration(
      this.moderationId
    )
  }

  fetchDetailsForUpdateModeration(id) {
    Moderation.fetchDetailsForUpdate(id)
      .then(({ data }) => {
        console.log(data)
        if (data.success) {
          this.brands = data.brands
          this.networks = data.networks
          this.cities = data.cities
          this.regions = data.regions
          this.statuses = data.statuses
          this.managers = data.data.managers
          this.dealershipDetails = data.data.dealershipDetails
          this.initiatorUser = data.data.initiatorUser
          this.getDadataAddress()
        }
      })
      .catch((errors) => {
        console.log(errors)
      })
  }

  deleteDealershipBrand(brandId) {
    // @ts-ignore
    const index = this.dealershipDetails.brandIds.indexOf(brandId)
    if (index !== -1) {
      // @ts-ignore
      this.dealershipDetails.brandIds.splice(index, 1)
    }
  }

  getDadataAddress() {
    // @ts-ignore
    Partners.getDadataAddress(this.dealershipDetails.location.address)
      .then(({ data }) => {
        if (data.success) {
          console.log(data)
          // @ts-ignore
          this.dealershipDetails.location.address = data.address
          // @ts-ignore
          this.dealershipDetails.location.cityId = data.cityId
          // @ts-ignore
          this.dealershipDetails.location.regionId = data.regionId
          // @ts-ignore
          this.dealershipDetails.location.lon = data.lon
          // @ts-ignore
          this.dealershipDetails.location.lat = data.lat
          this.cities = data.cities
          this.regions = data.regions
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
  }

  updateModeration() {
    Moderation.save(this.dealershipDetails, this.managers, this.moderationId)
      .then(({ data }) => {
        console.log(data)
        if (data.success === true) {
          // @ts-ignore
          this.showSuccessSystemNotification(data.msg)
          this.$router.push({ name: 'Partners' })
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
      .catch(() => {
        // @ts-ignore
        this.showErrorSystemNotification('Заполните поля для сохранения')
      })
  }
}
